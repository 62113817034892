import Plot2a from "../Plot/Plot2a";
import Plot2b from "../Plot/Plot2b";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

const Figure2 = ({projectId, pipelineId}) => {
    const [drugClass, setDrugClass] = useState('');
    const [timePoint, setTimePoint] = useState('');
    const allService = useSelector(state => state.allService);
    useEffect(() => {
        if (allService.plots.plot2a.content.length > 0) {
            const drug = Object.keys(allService.plots.plot2a.content[0])
                .filter(e => e !== 'timepoint').find(e => allService.plots.plot2a.content[0][e] !== 0);

            setDrugClass(drug);
            setTimePoint(allService.plots.plot2a.content[0]['timepoint']);
        }
    }, [allService.plots.plot2a.content]);
    return (
        <div className={"my-2"}>
            <h2 className="text-3xl font-bold mb-4">Figure 2</h2>
            <p>Show the abundances of the ARG drug classes existing in a specific timepoint, and how the they change across the time points</p>

            <h2 className="text-xl font-bold mt-6 mb-2">Description</h2>
            <p className="mb-4">The stacked barplot visually represents the abundance of antibiotic resistance genes (ARGs) across different drug classes at different timepoints. Clicking on a specific stack within the barplot triggers the display of a pie chart. This pie chart provides a detailed breakdown of the ARGs associated with the chosen drug class for that particular timepoint. Hovering the cursor over the segments in the piechart reveals the exact abundance values associated with a particular resistance gene for the selected timepoint.</p>

            <Plot2a width={1000} height={500} setDrugClass={setDrugClass} setTimePoint={setTimePoint} projectId={projectId} pipelineId={pipelineId} />
            <Plot2b width={1000} height={500} drugClass={drugClass} timePoint={timePoint} projectId={projectId} pipelineId={pipelineId} />
        </div>
    );
}

export default Figure2;