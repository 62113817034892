import {useState} from "react";
import Plot5 from "../Plot/Plot5";

const Figure5 = ({projectId, pipelineId}) => {
    const [arg, setArg] = useState("phylum");
    const [cluster, setCluster] = useState("K-means");
    const plot5ARGSelectionHandler = (option) => {
        setArg(option);
    }
    const plot5ClusterSelectionHandler = (option) => {
        setCluster(option);
    }
    return (
        <div className={"my-2"}>
            <h2 className="text-3xl font-bold mb-4">Figure 5</h2>
            <p>Scatter the samples (timepoints) based on the taxa-level species abundances</p>
            <h2 className="text-xl font-bold mt-6 mb-2">Description</h2>
            <p>In this figure, we cluster the time points based on ARG distribution. At first, we used non-metric multidimensional scaling (NMDS) on the ARG distribution of different time points. Then perform K-means clustering to view the similar distributed clusters together. The value of K is selected based on the silhouette score. Users can view the clustered time points in two different options - K means or based on season. Also, if they hover over the points they can view which time points they are.</p>
            <div className="flex flex-col mt-4">
                <p className={"font-bold"}>Taxa-level and ARG option</p>
                <div className="flex flex-row space-x-4">
                    <div className="form-control">
                        <label className="label cursor-pointer">
                            <span className="label-text mr-2">Phylum</span>
                            <input type="radio" name="ARGRadio" className="radio" checked={arg === "phylum"}
                                   onClick={() => plot5ARGSelectionHandler("phylum")}/>
                        </label>
                    </div>
                    <div className="form-control">
                        <label className="label cursor-pointer">
                            <span className="label-text mr-2">Class</span>
                            <input type="radio" name="ARGRadio" className="radio" checked={arg === "class"}
                                   onClick={() => plot5ARGSelectionHandler("class")}/>
                        </label>
                    </div>
                    <div className="form-control">
                        <label className="label cursor-pointer">
                            <span className="label-text mr-2">Order</span>
                            <input type="radio" name="ARGRadio" className="radio" checked={arg === "order"}
                                   onClick={() => plot5ARGSelectionHandler("order")}/>
                        </label>
                    </div>
                    <div className="form-control">
                        <label className="label cursor-pointer">
                            <span className="label-text mr-2">Family</span>
                            <input type="radio" name="ARGRadio" className="radio" checked={arg === "family"}
                                   onClick={() => plot5ARGSelectionHandler("family")}/>
                        </label>
                    </div>
                    <div className="form-control">
                        <label className="label cursor-pointer">
                            <span className="label-text mr-2">Genus</span>
                            <input type="radio" name="ARGRadio" className="radio" checked={arg === "genus"}
                                   onClick={() => plot5ARGSelectionHandler("genus")}/>
                        </label>
                    </div>
                    <div className="form-control">
                        <label className="label cursor-pointer">
                            <span className="label-text mr-2">Species</span>
                            <input type="radio" name="ARGRadio" className="radio" checked={arg === "species"}
                                   onClick={() => plot5ARGSelectionHandler("species")}/>
                        </label>
                    </div>
                    <div className="form-control">
                        <label className="label cursor-pointer">
                            <span className="label-text mr-2">Means</span>
                            <input type="radio" name="ARGRadio" className="radio" checked={arg === "means"}
                                   onClick={() => plot5ARGSelectionHandler("means")}/>
                        </label>
                    </div>
                </div>
            </div>
            <div className="flex flex-col mt-4">
                <p className={"font-bold"}>Clustering option</p>
                <div className="flex flex-row space-x-4">
                    <div className="form-control">
                        <label className="label cursor-pointer">
                            <span className="label-text mr-2">K-means</span>
                            <input type="radio" name="ClusterRadio" className="radio" checked={cluster === "K-means"}
                                   onClick={() => plot5ClusterSelectionHandler("K-means")}/>
                        </label>
                    </div>
                    <div className="form-control">
                        <label className="label cursor-pointer">
                            <span className="label-text mr-2">Season</span>
                            <input type="radio" name="ClusterRadio" className="radio" checked={cluster === "Season"}
                                   onClick={() => plot5ClusterSelectionHandler("Season")}/>
                        </label>
                    </div>
                </div>
            </div>

            <Plot5 width={800} height={600} arg={arg} cluster={cluster} projectId={projectId} pipelineId={pipelineId} />
        </div>
    );
}

export default Figure5;