import {useEffect, useRef, useState} from "react";
import {customColors, drawAxis2, margin} from "../../../../shared/utils/d3Utils";
import * as d3 from "d3";
import {useDispatch, useSelector} from "react-redux";
import {useConfig} from "../../../../shared/config/ConfigContext";
import {getPlot2aData} from "../../../../slices/allServiceSlice";

const Plot2a = ({width, height, setDrugClass, setTimePoint, projectId, pipelineId}) => {
    const dispatch = useDispatch();
    const { apiBaseUrl } = useConfig();
    const allService = useSelector(state => state.allService);
    const svgRef = useRef();
    const [data, setData] = useState([]);
    const xScale_eff = d3
        .scaleBand()
        .domain(data.map((d) => d.timepoint))
        .range([margin.left, width - margin.right - 150]);
    const yScale = d3
        .scaleLinear()
        .domain([0, 1])
        .range([height - margin.bottom, margin.top]);
    useEffect(() => {
        dispatch(getPlot2aData({apiBaseUrl, projectId, pipelineId}))
    }, [dispatch, apiBaseUrl])
    useEffect(() => {
        setData(allService.plots.plot2a.content)
    }, [allService.plots.plot2a.content])
    useEffect(() => {
        const svg = d3.select(svgRef.current);
        if (data.length !== 0) {
            svg.append("text")
                .attr("x", 50)
                .attr("y", 20)
                .style("font", "12px Arial")
                .html("Stacked Barplot Title");
            drawAxis2(
                svg,
                xScale_eff,
                "bottom",
                height - margin.bottom + 3,
                "Time Points"
            );
            drawAxis2(
                svg,
                yScale,
                "left",
                margin.left - 3,
                "Abundance Value"
            );
            const stack = d3
                .stack()
                .keys(
                    Object.keys(data[0]).filter(
                        (key) =>
                            key !== "timepoint" &&
                            key !== "MLS" &&
                            key !== "principal_component_1" &&
                            key !== "principal_component_2"
                    )
                )
                .order(d3.stackOrderNone)
                .offset(d3.stackOffsetNone);
            const colorScale = d3
                .scaleOrdinal()
                .domain(
                    Object.keys(data[0]).filter(
                        (key) =>
                            key !== "timepoint" &&
                            key !== "MLS" &&
                            key !== "principal_component_1" &&
                            key !== "principal_component_2"
                    )
                )
                .range(customColors);;
            const stackedData = stack(data);
            const timepointGroups = svg
                .selectAll(".timepoint")
                .data(stackedData)
                .enter()
                .append("g")
                .attr("class", "timepoint")
                .attr("fill", (d) => colorScale(d.key))
                .on("click", function (event, d) {
                    setDrugClass(d.key);
                });
            timepointGroups
                .selectAll("rect")
                .data((d) => d)
                .enter()
                .append("rect")
                .attr("x", (d) => xScale_eff(d.data.timepoint))
                .attr("y", (d) => yScale(d[1]))
                .attr("height", (d) => yScale(d[0]) - yScale(d[1]))
                .attr("width", xScale_eff.bandwidth())
                .append("title")
                .text((d) => `${parseFloat((d[1] - d[0]).toFixed(3))}`);
            timepointGroups.selectAll("rect").on("click", function (event, d) {
                setTimePoint(d.data.timepoint);
            });
            const legend = svg
                .append("g")
                .attr("class", "legend")
                .attr("transform", `translate(${width - 150}, 5)`);
            const legendRectSize = 18;
            const legendSpacing = 4;
            const legendItems = legend
                .selectAll(".legend-item")
                .data(
                    Object.keys(data[0]).filter(
                        (key) =>
                            key !== "timepoint" &&
                            key !== "MLS" &&
                            key !== "principal_component_1" &&
                            key !== "principal_component_2"
                    )
                )
                .enter()
                .append("g")
                .attr("class", "legend-item")
                .attr("transform", (d, i) => {
                    const yPos = i * (legendRectSize + legendSpacing) + 50;
                    return `translate(0, ${yPos})`;
                });

            legendItems
                .append("rect")
                .attr("width", legendRectSize)
                .attr("height", legendRectSize)
                .style("fill", (d) => colorScale(d));

            legendItems
                .append("text")
                .attr("x", legendRectSize + legendSpacing)
                .attr("y", legendRectSize - legendSpacing)
                .style("font-size", "12px")
                .text((d) => d);
            return () => svg.selectAll('*').remove();
        }
    }, [data])
    return (
        <svg ref={svgRef} width={width} height={height} />
    );
}

export default Plot2a;