import {RouteConstants} from "../../../shared/constants/RouteConstants";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useConfig} from "../../../shared/config/ConfigContext";
import {generateAssemblyVisualization, generateVisualization} from "../../../slices/allServiceSlice";

const CreateVisualizationDialog = ({projectId}) => {
    const allService = useSelector(state => state.allService);
    const dispatch = useDispatch();
    const { apiBaseUrl } = useConfig();
    const onCloseHandler = () => {
        formik.resetForm()
        document.getElementById('create_visualization_selection_modal').close();
    };
    const formik = useFormik({
        initialValues: {
            'pipeline': -1,
            'jobs': [],
        },
        onSubmit: async (values) => {
            const requestBody = {
                "project_id": projectId,
                "pipeline_id": values.pipeline,
                "list_of_jobs_id": values.jobs
            }
            console.log(requestBody.pipeline_id)
            let res;
             if (requestBody.pipeline_id === "8")
                 res = await dispatch(generateVisualization({apiBaseUrl, requestBody}))
             else
                 res = await dispatch(generateAssemblyVisualization({apiBaseUrl, requestBody}));
            if(res.payload)
                window.open(`${RouteConstants.visualizeRoute}/${projectId}/${values.pipeline}`);
            if (values)
                onCloseHandler();
        },
        validationSchema: Yup.object().shape({
            'pipeline': Yup.number()
                .min(1, 'Please select a pipeline'),
            'jobs': Yup.array()
                .min(2, 'Please select two or more valid job'),
        })
    });

    const handlePipelineChange = (event) => {
        formik.handleChange(event);
        formik.setFieldValue('jobs', []);
    };

    return (
        <dialog id={"create_visualization_selection_modal"} className={"modal md:modal-middle"}>
            <div className={"modal-box"}>
                <form
                    method={"dialog"}
                    className={"flex flex-col items-center py-4"}
                    onSubmit={formik.handleSubmit}
                >
                    <h1 className={"mb-8 font-bold text-xl md:text-3xl"}>Create Visualization</h1>
                    <div className={"form-control w-full max-w-xs"}>
                        <select
                            id={"pipeline"}
                            className={"select select-bordered md:select-md select-sm w-full max-w-xs"}
                            value={formik.values['pipeline'] || ''}
                            onChange={handlePipelineChange}
                        >
                            <option disabled value={-1}>
                                Pick your Pipeline
                            </option>
                            {
                                allService.pipeline.map(e => (
                                    <option
                                        key={e.pipeline_id}
                                        value={e.pipeline_id}
                                    >
                                        {e.name}
                                    </option>
                                ))
                            }
                        </select>
                        <label className="label">
                            <span
                                className="label-text-alt text-red-500">{formik.touched["pipeline"] && formik.errors["pipeline"]}</span>
                        </label>
                    </div>
                    <div className={"form-control w-full max-w-xs"}>
                        <span className={"mb-8 font-bold text-l md:text-2xl"}>Select Jobs</span>
                        <div role="group" aria-labelledby="checkbox-group">
                            {
                                allService.job.filter(e => e.pipeline.pipeline_id.toString() === formik.values.pipeline.toString())
                                    .filter(e => e['job_status_type']['job_status_type_id'] === "CM")
                                    .map(e => (
                                        <label key={e.jobId} className={"flex items-center label cursor-pointer"}>
                                            <input type={"checkbox"} name={"jobs"} value={e.job_id}
                                                   className={"checkbox"}
                                                   onChange={formik.handleChange}/>
                                            <span className="label-text">{e.name}</span>
                                        </label>
                                    ))
                            }
                        </div>
                        <label className="label">
                            <span
                                className="label-text-alt text-red-500">{formik.touched["jobs"] && formik.errors["jobs"]}</span>
                        </label>
                    </div>
                    <div className={"text-center text-error md:text-md sm:text-sm text-xs mt-2"}>
                        Warning: Choosing duplicate samples could negatively impact results.
                    </div>
                    <div>
                        <button
                            type={"submit"}
                            className={"btn md:mx-3 md:mt-4 md:px-8 md:btn-sm btn-xs mx-2 btn-accent"}
                            disabled={formik.isSubmitting}
                        >
                            Create
                        </button>
                        <button
                            type={"button"}
                            onClick={onCloseHandler}
                            className={"btn md:mx-3 md:mt-4 md:px-8 md:btn-sm btn-xs mx-2"}
                            disabled={formik.isSubmitting}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </dialog>
    );
}

export default CreateVisualizationDialog;