import NSFLogo from "../../shared/images/NSF_Official_logo_400x.png";
import CIWARSFigure1 from "../../shared/images/Figure_cloud_1_CIWARS_web.png";
import {useDispatch, useSelector} from "react-redux";
import {useConfig} from "../../shared/config/ConfigContext";
import {getSponsorIntroContent, getWelcomeContent} from "../../slices/allServiceSlice";
import {useEffect} from "react";


const About = () => {
    const dispatch = useDispatch();
    const { apiBaseUrl } = useConfig();
    const allService = useSelector(state => state.allService);
    useEffect(() => {
        dispatch(getWelcomeContent({apiBaseUrl, key: 'welcome-content'}));
        dispatch(getSponsorIntroContent({apiBaseUrl, key: 'sponsor-intro-content'}));
    }, [dispatch, apiBaseUrl])
    return (
        <div className="flex flex-col items-center min-h-screen text-xl">
            <img src={NSFLogo} alt={"..."} className={"fixed w-36 bottom-0 left-0 md:w-48"}/>
            <div className="w-3/5 text-start mt-12">
                <p className={"mb-4"}>
                    <div dangerouslySetInnerHTML={{__html: allService.welcomeContent}}/>
                </p>
                <p className={"mb-4"}>
                    <div dangerouslySetInnerHTML={{__html: allService.sponsorIntroContent}}/>
                </p>
                <p className={"mb-4"}>
                    <div>
                        CIWARS was primarily supported by NSF CSSI Award 2004751, "Frameworks: Developing CyberInfrastructure for Waterborne Antibiotic Resistance Risk Surveillance (CI4-WARS)".  Student support was additionally provided by NSF NRT Award 2125798, "NRT-HDR: Convergence at the Interfaces of Policy, Data Science, Environmental Science and Engineering to Combat the Spread of Antibiotic Resistance (CIP-CAR NRT)."
                    </div>
                </p>
                <img className={"mb-5"} src={CIWARSFigure1} alt={"..."}/>
            </div>
        </div>
    );
}

export default About;
