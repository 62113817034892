import {useEffect, useRef, useState} from "react";
import * as Plot from "@observablehq/plot";
import {useDispatch, useSelector} from "react-redux";
import {useConfig} from "../../../../shared/config/ConfigContext";
import {getPlot9Data} from "../../../../slices/allServiceSlice";

const Plot9 = ({width, height, projectId, pipelineId}) => {
    const dispatch = useDispatch();
    const { apiBaseUrl } = useConfig();
    const allService = useSelector(state => state.allService);
    const svgRef = useRef();
    const [data, setData] = useState([]);
    useEffect(() => {
        dispatch(getPlot9Data({apiBaseUrl, projectId, pipelineId}))
        const tmp = []
        allService.plots.plot9.content.forEach(item => {
            tmp.push({timepoint: item.timepoint, type: "anomaly", num_of_ARG: item.anomaly ? item.anomaly : 0})
            tmp.push({timepoint: item.timepoint, type: "normal", num_of_ARG: item.normal ? item.normal : 0 })
        })
        setData(tmp)
    }, []);
    useEffect(() => {
        if (data.length !== 0) {
            const plot = Plot.plot({
                x: { label: null },
                y: { tickFormat: "s", tickSpacing: 50 },
                color: {
                    scheme: "Spectral",
                    legend: "ramp",
                    width: 340,
                    label: "ARG"
                },
                marks: [
                    Plot.barY(data, {
                        x: "timepoint",
                        y: "num_of_ARG",
                        fill: "type",
                        sort: { color: null, x: "-y" },
                        tip: true
                    })
                ],
                width,
                height,
                margin: 40
            });
            svgRef.current.append(plot);
            return () => plot.remove();
        }
    }, [data])
    return (
        <div ref={svgRef} />
    )
}

export default Plot9;