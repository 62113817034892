import Plot9 from "../Plot/Plot9";

const Figure9 = ({projectId, pipelineId}) => {
    return (
        <div className={"my-2"}>
            <h2 className="text-3xl font-bold mb-4">Figure 9</h2>
            <h2 className="text-xl font-bold mt-4 mb-2">Anomaly summary</h2>
            <p>The below figure shows the number of anomaly candidates of ARGs and normal ARGS for each sample.</p>

            <Plot9 width={928} height={500} projectId={projectId} pipelineId={pipelineId} />
        </div>
    )
}

export default Figure9;