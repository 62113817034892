import React from 'react';
import AssemblyPlot1 from "../Plot/AssemblyPlot1";

const AssemblyFigure1 = ({projectId, pipelineId}) => {
    return (
        <div className={"my-2"}>
            <h2 className="text-3xl font-bold mb-4">Figure 1</h2>
            <p className={"font-bold"}>Identify the anomalous timepoints for Resistome risk score</p>

            <h2 className="text-xl font-bold mt-6 mb-2">Description</h2>
            <p className="mb-4">The below figure shows the trend of the Resistome risk score across timepoints. The anomalies were identified based on IQR rules.</p>

            <AssemblyPlot1 width={800} height={500} projectId={projectId} pipelineId={pipelineId}/>

        </div>
    );
};

export default AssemblyFigure1;