import React, {useEffect, useRef, useState} from 'react';
import * as Plot from "@observablehq/plot";
import {useDispatch, useSelector} from "react-redux";
import {useConfig} from "../../../../shared/config/ConfigContext";
import {getAssemblyPlot1} from "../../../../slices/allServiceSlice";

const AssemblyPlot1 = ({width, height, projectId, pipelineId}) => {
    const svgRef = useRef();
    const dispatch = useDispatch();
    const {apiBaseUrl} = useConfig();
    const allService = useSelector(state => state.allService);
    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(getAssemblyPlot1({apiBaseUrl, projectId, pipelineId}));
    }, [dispatch, apiBaseUrl]);
    useEffect(() => {
        setData(allService.plots.assemblyPlot1.content)
    }, [allService.plots.assemblyPlot1.content])
    useEffect(() => {
        if (data.length) {
            const minimum_risk_score = data.reduce((prev, curr) => prev.ResistomeRiskScore < curr.ResistomeRiskScore ? prev : curr).ResistomeRiskScore;
            const maximum_risk_score = data.reduce((prev, curr) => prev.ResistomeRiskScore > curr.ResistomeRiskScore ? prev : curr).ResistomeRiskScore;
            const plot = Plot.plot({
                color: {
                    range: ["orange", "green"],
                    legend: true
                },
                x: {
                    tickRotate: -45,
                    ticks: 5,
                    tickSize: 5,
                    line: true,
                    tickPadding: 5,
                    label: "Timepoint",
                    labelAnchor: "left",
                    labelOffset: 0,
                    nice: true
                },
                y: {
                    grid: true,
                    label: "Resistome Risk score",
                    line: true,
                    domain: [minimum_risk_score - 5, maximum_risk_score + 5]
                },
                marks: [
                    Plot.line(data, {
                        sort: "Timepoint",
                        x: "Timepoint",
                        y: "ResistomeRiskScore",
                        marker: "circle"
                    }),
                    Plot.dot(data, {
                        sort: "Timepoint",
                        x: "Timepoint",
                        y: "ResistomeRiskScore",
                        stroke: "is_anomaly",
                        fill: "is_anomaly",
                        tip: true
                    })
                ],
                marginBottom: 80,
                marginLeft: 60
            })
            svgRef.current.append(plot);
            return () => plot.remove();
        }
    }, [data]);
    return (
        <div ref={svgRef}/>
    );
};

export default AssemblyPlot1;