import {useEffect, useState} from "react";
import Plot8 from "../Plot/Plot8";
import {useDispatch, useSelector} from "react-redux";
import {useConfig} from "../../../../shared/config/ConfigContext";
import {getPlot8Data} from "../../../../slices/allServiceSlice";

const Figure8 = ({projectId, pipelineId}) => {
    const dispatch = useDispatch();
    const { apiBaseUrl } = useConfig();
    const allService = useSelector(state => state.allService);
    const [metadataFeature, setMetadataFeature] = useState('');
    const [metadataChoice, setMetadataChoice] = useState([]);
    const optionOnChangeHandler = (event) => {
        setMetadataFeature(event.target.value);
    }
    const getUniqueFeatures = (arr) => {
        const featuresSet = new Set();
        arr.forEach(obj => {
            if (obj.feature) {
                featuresSet.add(obj.feature);
            }
        });
        return Array.from(featuresSet);
    };
    useEffect(() => {
        dispatch(getPlot8Data({apiBaseUrl, projectId, pipelineId}))
    }, []);
    useEffect(() => {
        if (allService.plots.plot8.content !== 'failed to fetch file') {
            setMetadataChoice(getUniqueFeatures(allService.plots.plot8.content))
            setMetadataFeature(getUniqueFeatures(allService.plots.plot8.content)[0]);
        }}, [allService.plots.plot8.content]);
    return (
        <div className={"my-4"}>
            <h2 className="text-3xl font-bold mb-4">Figure 8</h2>
            <h2 className="text-xl font-bold mt-4 mb-2">Identify the anomalous timepoints for each feature in metadata</h2>
            {
                metadataChoice.length > 0 ?
                    <div>
                        <p>The below figure shows the trend of the metadata feature across timepoints. The anomalies
                            were
                            identified based on IQR rules.</p>
                        <div className="form-control max-w-sm mr-12 mt-4">
                            <label className="label">
                                <span className="label-text font-bold">Choose metadata feature!</span>
                            </label>
                            <select className="select select-bordered w-xs" value={metadataFeature}
                                    onChange={optionOnChangeHandler}>
                                {
                                    metadataChoice.map(e => (
                                        <option key={e} value={e}>{e}</option>
                                    ))
                                }
                            </select>
                            <label className="label">
                                <span className="label-text-alt text-gray-400 italic">Please select the feature</span>
                            </label>
                        </div>

                        <Plot8 width={800} height={500} option={metadataFeature} />
                    </div>
                    :
                    <div className={"mb-12"}>
                        <h2 className="text-xl font-bold my-3 mt-4 text-red-500">No data for anomalous timepoints</h2>
                    </div>
            }
        </div>
    )
}

export default Figure8;