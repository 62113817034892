import {useEffect, useState} from "react";
import Plot7a from "../Plot/Plot7a";
import Plot7b from "../Plot/Plot7b";
import {useDispatch, useSelector} from "react-redux";
import {useConfig} from "../../../../shared/config/ConfigContext";
import {getArgAnomaly, getArgNormal, getPlot7aData, getPlot7bData} from "../../../../slices/allServiceSlice";

const Figure7 = ({projectId, pipelineId}) => {
    const [anomalyList, setAnomalyList] = useState([]);
    const [anomalyOption, setAnomalyOption] = useState('');
    const [normalList, setNormalList] = useState([]);
    const [normalOption, setNormalOption] = useState('');
    const dispatch = useDispatch();
    const { apiBaseUrl } = useConfig();
    const allService = useSelector(state => state.allService);
    useEffect(() => {
        dispatch(getArgAnomaly({apiBaseUrl, projectId, pipelineId}))
        dispatch(getArgNormal({apiBaseUrl, projectId, pipelineId}))
        dispatch(getPlot7aData({apiBaseUrl, projectId, pipelineId}))
        dispatch(getPlot7bData({apiBaseUrl, projectId, pipelineId}))
        setAnomalyList(allService.plots.arg_anomaly.content);
        setNormalList(allService.plots.arg_normal.content);
    }, []);
    useEffect(() => {
        if (allService.plots.arg_anomaly.content.length > 0)
            setAnomalyOption(allService.plots.arg_anomaly.content[0]);
        if (allService.plots.arg_normal.content.length > 0)
            setNormalOption(allService.plots.arg_normal.content[0]);
    }, [allService.plots.arg_anomaly.content, allService.plots.arg_normal.content])
    const anomalousOptionOnChangeHandler = (event) => {
        setAnomalyOption(event.target.value);
    }
    const normalOptionOnChangeHandler = (event) => {
        console.log(event.target.value)
        setNormalOption(event.target.value);
    }
    return (
        <div className={"my-2"}>
            <h2 className="text-3xl font-bold mb-4">Figure 7</h2>
            {
                anomalyList.length === 0 ?
                    <div className={"mb-12"}>
                        <h2 className="text-xl font-bold mt-4 mb-2 text-red-500">No data for anomalous timepoints</h2>
                    </div>
                    :
                    <div>
                        <h2 className="text-xl font-bold mt-4 mb-2">Identify the anomalous timepoints for each ARG</h2>
                        <p>The below figure shows the trend of the 16S normalized abundance for ARGs across timepoints. The anomalies were identified based on IQR rules.</p>

                        <div className="form-control max-w-sm mr-12">
                            <label className="label">
                                <span className="label-text font-bold">Choose anomalous ARG!</span>
                            </label>
                            <select className="select select-bordered w-xs" value={anomalyOption} onChange={anomalousOptionOnChangeHandler}>
                                {
                                    anomalyList.map(e => (
                                        <option key={e} value={e}>{e}</option>
                                    ))
                                }
                            </select>
                            <label className="label">
                                <span className="label-text-alt text-gray-400 italic">Please select the ARG</span>
                            </label>
                        </div>
                        <Plot7a width={900} height={500} option={anomalyOption} projectId={projectId} pipelineId={pipelineId} />
                    </div>
            }

            {
                normalList.length === 0 ?
                    <div className={"mb-12"}>
                        <h2 className="text-xl font-bold my-3 mt-4 text-red-500">No data for normal timepoints</h2>
                    </div>
                    :
                    <div>
                        <h2 className="text-xl font-bold my-3 mt-4">Identify the normal timepoints for each ARG</h2>

                    <div className="form-control max-w-sm mr-12">
                        <label className="label">
                            <span className="label-text font-bold">Choose normal ARG!</span>
                        </label>
                        <select className="select select-bordered w-xs" value={normalOption}
                                onChange={normalOptionOnChangeHandler}>
                            {
                                normalList.map(e => (
                                    <option key={e} value={e}>{e}</option>
                                ))
                            }
                        </select>
                        <label className="label">
                            <span className="label-text-alt text-gray-400 italic">Please select the ARG</span>
                        </label>
                    </div>
                    <Plot7b width={900} height={500} option={normalOption} projectId={projectId}
                            pipelineId={pipelineId}/>
                </div>
            }
        </div>
    );
}

export default Figure7;