import {useEffect, useRef, useState} from "react";
import * as d3 from "d3";
import * as Plot from "@observablehq/plot";
import {useDispatch, useSelector} from "react-redux";
import {useConfig} from "../../../../shared/config/ConfigContext";
import {getPlot5Data} from "../../../../slices/allServiceSlice";

const Plot5 = ({width, height, arg, cluster, projectId, pipelineId}) => {
    const dispatch = useDispatch();
    const { apiBaseUrl } = useConfig();
    const allService = useSelector(state => state.allService);
    const svgRef = useRef();
    const [data, setData] = useState([]);

    const getSeason = (month) => {
        if (month >= 3 && month <= 4) {
            return "Spring";
        } else if (month >= 5 && month <= 8) {
            return "Summer";
        } else if (month >= 9 && month <= 10) {
            return "Autumn";
        } else {
            return "Winter";
        }
    }
    const colorScaleKMeans = d3.scaleOrdinal(d3.schemeCategory10);
    const colorScaleSeason = d3
        .scaleOrdinal()
        .domain(["Spring", "Summer", "Autumn", "Winter"])
        .range(["#ff7f00", "#1f78b4", "#33a02c", "#e31a1c"]);
    const getColorScale = (cluster_type_param, d) => {
        if (cluster_type_param === "K-means") {
            return colorScaleKMeans(d.cluster_number);
        } else {
            return colorScaleSeason(getSeason(parseInt(d.timepoint.split("-")[1])));
        }
    }
    useEffect(() => {
        dispatch(getPlot5Data({apiBaseUrl, projectId, pipelineId, choice: arg}))
    }, [dispatch, apiBaseUrl, arg])
    useEffect(() => {
        setData(allService.plots.plot5.content)
    }, [allService.plots.plot5.content])
    useEffect(() => {
        if (data.length !== 0) {
            const xaxis_min_val_fig5 = d3.min(data, (r) => r.nMDS_component_1) - 1
            const yaxis_min_val_fig5 = d3.min(data, (r) => r.nMDS_component_2) - 1
            const plot = Plot.plot({
                width,
                height,
                marks: [
                    Plot.ruleX([xaxis_min_val_fig5]),
                    Plot.ruleY([yaxis_min_val_fig5]),
                    Plot.dot(data, {
                        x: "nMDS_component_1",
                        y: "nMDS_component_2",
                        fill: (d) => getColorScale(cluster, d),
                        r: 7, // Initial size of the circles
                        tip: true,
                        title: (d) => d.timepoint
                    })
                ],
                margin: 50
            });
            svgRef.current.append(plot);
            return () => plot.remove();
        }
    }, [data, cluster])
    return (
        <div className={"flex flex-col py-4"}>
            {
                cluster === "Season" &&
                <div className={"flex flex-row pb-4 pl-8"}>
                    <div className="mr-4">
                        <div className={"w-[15px] h-[15px]"} style={{backgroundColor: "#ff7f00"}}></div>
                        <span>Spring</span>
                    </div>
                    <div className="mr-4">
                        <div className={"w-[15px] h-[15px]"} style={{backgroundColor: "#1f78b4"}}></div>
                        <span>Summer</span>
                    </div>
                    <div className="mr-4">
                        <div className={"w-[15px] h-[15px]"} style={{backgroundColor: "#33a02c"}}></div>
                        <span>Autumn</span>
                    </div>
                    <div className="mr-4">
                        <div className={"w-[15px] h-[15px]"} style={{backgroundColor: "#e31a1c"}}></div>
                        <span>Winter</span>
                    </div>
                </div>
            }
            <div ref={svgRef} />
        </div>
    )
}

export default Plot5;