import {useState} from "react";
import Plot3 from "../Plot/Plot3";

const Figure3 = ({projectId, pipelineId}) => {
    const [level, setLevel] = useState("phylum");
    const plot3SelectionHandler = (option) => {
        setLevel(option);
    }
    return (
        <div className={"my-2"}>
            <h2 className="text-3xl font-bold mb-4">Figure 3</h2>
            <ul className={"py-1"}>
                <li className={"py-1"}>
                    <p>Show the correlation heatmap visualizing the degree of correlation between the OTUs using all samples</p>
                </li>
                <li className={"pt-1"}><p>User can choose the taxa-level below:</p></li>
            </ul>
            <div className="flex flex-row space-x-4 mt-4">
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <span className="label-text mr-2">Phylum</span>
                        <input type="radio" name="Plot3Radio" className="radio" checked={level === "phylum"}
                               onClick={() => plot3SelectionHandler("phylum")}/>
                    </label>
                </div>
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <span className="label-text mr-2">Class</span>
                        <input type="radio" name="Plot3Radio" className="radio" checked={level === "class"}
                               onClick={() => plot3SelectionHandler("class")}/>
                    </label>
                </div>
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <span className="label-text mr-2">Order</span>
                        <input type="radio" name="Plot3Radio" className="radio" checked={level === "order"}
                               onClick={() => plot3SelectionHandler("order")}/>
                    </label>
                </div>
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <span className="label-text mr-2">Family</span>
                        <input type="radio" name="Plot3Radio" className="radio" checked={level === "family"}
                               onClick={() => plot3SelectionHandler("family")}/>
                    </label>
                </div>
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <span className="label-text mr-2">Genus</span>
                        <input type="radio" name="Plot3Radio" className="radio" checked={level === "genus"}
                               onClick={() => plot3SelectionHandler("genus")}/>
                    </label>
                </div>
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <span className="label-text mr-2">Species</span>
                        <input type="radio" name="Plot3Radio" className="radio" checked={level === "species"}
                               onClick={() => plot3SelectionHandler("species")}/>
                    </label>
                </div>
            </div>
            <Plot3 width={700} height={700} level={level} projectId={projectId} pipelineId={pipelineId}/>
        </div>
    );
}

export default Figure3;