import Figure1 from "./ReadBased/Figure/Figure1";
import Figure2 from "./ReadBased/Figure/Figure2";
import Figure3 from "./ReadBased/Figure/Figure3";
import Figure4 from "./ReadBased/Figure/Figure4";
import Figure5 from "./ReadBased/Figure/Figure5";
import Figure6 from "./ReadBased/Figure/Figure6";
import Figure7 from "./ReadBased/Figure/Figure7";
import Figure8 from "./ReadBased/Figure/Figure8";
import Figure9 from "./ReadBased/Figure/Figure9";
import AssemblyFigure1 from "./AssemblyBased/Figure/AssemblyFigure1";

const Visualization = (props) => {
    const projectId = props.match.params.projectId;
    const pipelineId = parseInt(props.match.params.pipelineId, 10);
    return (
        <div className="mx-36 my-4 px-12 py-8 bg-gray-100 min-w-[1200px]">
            <h1 className="text-4xl font-bold mb-8">
                Visualization of CIWARS pipeline outputs
            </h1>
            {
                pipelineId === 8 ?
                    (
                        <div>
                            <Figure1 projectId={projectId} pipelineId={pipelineId}/>
                            <Figure2 projectId={projectId} pipelineId={pipelineId}/>
                            <Figure3 projectId={projectId} pipelineId={pipelineId}/>
                            <Figure4 projectId={projectId} pipelineId={pipelineId}/>
                            <Figure5 projectId={projectId} pipelineId={pipelineId}/>
                            <Figure6 projectId={projectId} pipelineId={pipelineId}/>
                            <Figure7 projectId={projectId} pipelineId={pipelineId}/>
                            <Figure8 projectId={projectId} pipelineId={pipelineId}/>
                            <Figure9 projectId={projectId} pipelineId={pipelineId}/>
                        </div>
                    )
                    :
                    (
                        <div>
                            <AssemblyFigure1 projectId={projectId} pipelineId={pipelineId}/>
                        </div>
                    )
            }
        </div>
    );
}

export default Visualization;