import Plot4 from "../Plot/Plot4";

const Figure4 = ({projectId, pipelineId}) => {
    return (
        <div className={"my-2"}>
            <h2 className="text-3xl font-bold mb-4">Figure 4</h2>
            <p>Show the correlation heatmap visualizing the degree of correlation between the timepoints based on the relative abundance of all species</p>
            <Plot4 width={700} height={700} projectId={projectId} pipelineId={pipelineId} />
        </div>
    );
}

export default Figure4;