import {useEffect, useRef, useState} from "react";
import * as Plot from "@observablehq/plot";
import {useSelector} from "react-redux";

const Plot8 = ({width, height, option}) => {
    const allService = useSelector(state => state.allService);
    const svgRef = useRef();
    const [data, setData] = useState([]);
    useEffect(() => {
        setData(allService.plots.plot8.content)
    }, [allService.plots.plot8.content])
    useEffect(() => {
        if (data.length !== 0) {
            const plot = Plot.plot({
                color: {
                    range: ["orange", "green"],
                    legend: true
                },
                x: {
                    tickRotate: -45,
                    ticks: 5,
                    tickSize: 5,
                    line: true,
                    tickPadding: 5,
                    label: "timepoint",
                    labelAnchor: "left",
                    labelOffset: 0,
                    nice: true
                },
                y: {
                    grid: true
                },
                marks: [
                    Plot.line(data.filter(e => e.feature === option), {
                        sort: "timepoint",
                        x: "timepoint",
                        y: "value",
                        marker: "circle"
                    }),
                    Plot.dot(data.filter(e => e.feature === option), {
                        sort: "timepoint",
                        x: "timepoint",
                        y: "value",
                        stroke: "is_anomaly",
                        fill: "is_anomaly",
                        tip: true
                    })
                ],
                width,
                height,
                marginBottom: 80
            });
            svgRef.current.append(plot);
            return () => plot.remove();
        }
    }, [data, option])
    return (
        <div ref={svgRef} />
    )
}

export default Plot8;