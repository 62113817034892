import Plot1 from "../Plot/Plot1";
import {useState} from "react";

const Figure1 = ({projectId, pipelineId}) => {
    const [level, setLevel] = useState("phylum");
    const plot1SelectionHandler = (option) => {
        setLevel(option);
    }
    return (
        <div className={"my-2"}>
            <h2 className="text-3xl font-bold mb-4">Figure 1</h2>
            <p>Show the abundances of the bacterial classes existing in a specific timepoint, and how the class-level
                composition changes across the time points</p>

            <h2 className="text-xl font-bold mt-6 mb-2">Description</h2>
            <p className="mb-4">The stacked bar charts show the different taxa-level abundances of different
                time-points. Selecting a taxa-level using the radio buttons will show the bacterial abundance values for
                that level. To note, here, we kept only the top 20 entities that exist dominantly in the user's
                time-series dataset for the chosen taxa-level.</p>

            <h3 className="text-xl font-bold mt-6 mb-2">Variables</h3>

            <h3 className="text-xl font-bold mt-6 mb-2">Bar chart showing the class-level composition changes</h3>
            <div className="flex flex-row space-x-4 mt-4">
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <span className="label-text mr-2">Phylum</span>
                        <input type="radio" name="Plot1Radio" className="radio" checked={level === "phylum"}
                               onClick={() => plot1SelectionHandler("phylum")}/>
                    </label>
                </div>
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <span className="label-text mr-2">Class</span>
                        <input type="radio" name="Plot1Radio" className="radio" checked={level === "class"}
                               onClick={() => plot1SelectionHandler("class")}/>
                    </label>
                </div>
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <span className="label-text mr-2">Order</span>
                        <input type="radio" name="Plot1Radio" className="radio" checked={level === "order"}
                               onClick={() => plot1SelectionHandler("order")}/>
                    </label>
                </div>
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <span className="label-text mr-2">Family</span>
                        <input type="radio" name="Plot1Radio" className="radio" checked={level === "family"}
                               onClick={() => plot1SelectionHandler("family")}/>
                    </label>
                </div>
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <span className="label-text mr-2">Genus</span>
                        <input type="radio" name="Plot1Radio" className="radio" checked={level === "genus"}
                               onClick={() => plot1SelectionHandler("genus")}/>
                    </label>
                </div>
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <span className="label-text mr-2">Species</span>
                        <input type="radio" name="Plot1Radio" className="radio" checked={level === "species"}
                               onClick={() => plot1SelectionHandler("species")}/>
                    </label>
                </div>
            </div>
            <Plot1 class="mt-8" height={550} width={1000} level={level} projectId={projectId} pipelineId={pipelineId} />
        </div>
    )
}

export default Figure1;